import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyDdUgFLYeNkWcerk-u7_TTxz619MoSK3OA',
  authDomain: 'patches-and-stickers.firebaseapp.com',
  projectId: 'patches-and-stickers',
  storageBucket: 'patches-and-stickers.appspot.com',
  messagingSenderId: '317135073758',
  appId: '1:317135073758:web:783272d601db11f9fb6d4f',
  measurementId: 'G-R4ERZQ86E9'
});
console.log(`We are now connected to Firebase App ${firebaseApp.name}`);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

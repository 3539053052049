/* eslint-disable no-undef */
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from '../error/Error';
import Home from '../home/Home';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Help } from '../help/Help';
import Confirmation from '../confirmation/Confirmation';
import OrderContainer from '../order/OrderContainer';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: '/order',
    element: <OrderContainer />,
    errorElement: <Home />
  },
  {
    path: '/confirmation',
    element: <Confirmation />,
    errorElement: <Home />
  },
  {
    path: '/help',
    element: <Help />,
    errorElement: <Home />
  }
]);

export const Main = () => {
  return (
    <PayPalScriptProvider
      options={{
        // eslint-disable-next-line no-undef
        'client-id':
          process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
            ? process.env.REACT_APP_PAYPAL_CLIENT_ID_DEVELOPMENT
            : process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION
      }}>
      <RouterProvider router={router} />
    </PayPalScriptProvider>
  );
};

/* eslint-disable no-undef */
import { PriceMultiple, ShippingRates } from '../utils/Constants';
import emailjs from '@emailjs/browser';

export function calculatePrice(orderType, area, quantity) {
  let priceMultiplier;
  let shipping;

  if (quantity < 25) {
    priceMultiplier = PriceMultiple.under25;
    shipping = ShippingRates.under25;
  } else if (quantity >= 25 && quantity < 50) {
    priceMultiplier = PriceMultiple.over25;
    shipping = ShippingRates.over25;
  } else if (quantity >= 50) {
    priceMultiplier = PriceMultiple.over50;
    shipping = ShippingRates.over50;
  }

  let price = area * (orderType.price * priceMultiplier);
  if (area <= 10) {
    price += 0.5;
  } else {
    price += 0.25;
  }

  price = price.toFixed(2);
  const itemPrice = (price * quantity).toFixed(2);
  const total = (parseFloat(itemPrice) + shipping).toFixed(2);

  return {
    price: price,
    shipping: shipping,
    itemPrice: itemPrice,
    total: total
  };
}

// Convert the file size to a readable format
export const formatFileSize = (bytes) => {
  const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};

export const getFileSize = (file) => {
  return (file.size / Math.pow(1024, 1)).toFixed(2);
};

export const addElemenToForm = (name, value) => {
  const element = document.createElement('input');
  element.setAttribute('type', 'text');
  element.setAttribute('name', name);
  element.setAttribute('value', value);
  element.style['display'] = 'none';
  return element;
};

export const sendEmail = (form, e, templateId) => {
  e?.preventDefault();

  return emailjs
    .sendForm(
      process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
      templateId,
      form,
      process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
    )
    .then((result) => {
      console.log(result.text);
      return true;
    })
    .catch((error) => {
      console.log(error.text);
      return false;
    });
};

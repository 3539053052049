import { useLocation, useNavigate } from 'react-router-dom';
import { Shape } from '../../utils/Constants';
import './Confirmation.css';
import Header from '../header/Header';

const Confirmation = () => {
  const location = useLocation();
  const orderData = location.state;
  const navigate = useNavigate();

  const dimensions = () => {
    if (orderData.shape === Shape.circle) {
      return (
        <label>
          <span>Diameter:</span> {orderData.diameter} inche(s)
        </label>
      );
    } else if (orderData.shape === Shape.other) {
      return (
        <>
          <label>
            <span>Length:</span> {orderData.length} inche(s)
          </label>
          <label>
            <span>Width:</span> {orderData.width} inche(s)
          </label>
        </>
      );
    }
  };

  return (
    <>
      <Header />
      <div className="Confirmation">
        <div style={{ margin: '.5rem' }} className="reviewForm">
          <h1>Confirmation Details</h1>
          <h2 style={{ paddingBottom: '1rem' }}>(print for your own records)</h2>
          <label>
            <span>Order ID:</span> {orderData.formData.orderId}
          </label>
          <label>
            <span>Submission Date:</span> {orderData.formData.date}
          </label>
          <label>
            <span>Shape:</span> {orderData.formData.shape}
          </label>
          {dimensions()}
          <label>
            <span>Quantity:</span> {orderData.formData.quantity}
          </label>
          <label>
            <span>Item Total:</span> ${orderData.formData.itemPrice}
          </label>
          <label>
            <span>Shipping:</span> ${orderData.formData.shipping} (allow up to 1 week to ship)
          </label>
          <label>
            <span>Price:</span> ${orderData.formData.total} (${orderData.formData.price} each)
          </label>
          <img
            style={{ height: 'auto', width: '10rem', alignSelf: 'center' }}
            src={orderData.formData.imageUrl}
          />
        </div>
        <div className="formCol">
          <div className="buttonCol">
            <button
              style={{ alignSelf: 'stretch', margin: 0, marginBottom: '0.5rem' }}
              className="button"
              onClick={() => navigate('/')}>
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;

import Header from './components/header/Header';
import { Main } from './components/main/Main';

const appStyles = {
  display: 'grid',
  gridTemplateRows: 'min-content auto min-content'
};

function App() {
  return (
    <div style={appStyles}>
      <Main />
    </div>
  );
}

export default App;

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addElemenToForm,
  calculatePrice,
  getFileSize,
  sendEmail
} from '../../services/OrderService';
import { OrderType, Shape, templateIds } from '../../utils/Constants';
import './Order.scss';
import './Review.css';
import uploadImage from '../../assets/upload.png';
import removeImage from '../../assets/removeImg.png';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import Header from '../header/Header';

const OrderContainer = () => {
  const [{ isPending }] = usePayPalScriptReducer();

  const [newOrder, setNewOrder] = useState(true);

  const [shape, setShape] = useState(Shape.circle);
  const [diameter, setDiameter] = useState(1);
  const [length, setLength] = useState(1);
  const [width, setWidth] = useState(1);
  const [area, setArea] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState(false);
  const [shipping, setShipping] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [file, setFile] = useState(null); // Handles input change event and updates state
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const orderType = OrderType.sticker;
  const form = useRef();
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    if (file) {
      const size = getFileSize(file);

      if (size > 500) {
        errorMessages['fileSize'] = 'File size cannot exceed 500kB';
        setErrorMessages(errorMessages);
        setFileSizeError(true);

        setImageUrl(null);
        setFile(null);
      } else {
        errorMessages['fileSize'] = '';
        setErrorMessages(errorMessages);
        setFileSizeError(false);
        setImageUrl(URL.createObjectURL(file));
      }
    } else {
      setImageUrl(null);
    }
  }, [file]);

  useEffect(() => {
    if (shape === Shape.circle) {
      setLength(1);
      setWidth(1);

      setArea(diameter * diameter);
    } else if (shape === Shape.other) {
      setDiameter(1);

      setArea(length * width);
    }
  }, [shape, length, width, diameter]);

  useEffect(() => {
    if (!quantityError) {
      const calculatedPrice = calculatePrice(orderType, area, quantity);
      setPrice(calculatedPrice.price);
      setTotal(calculatedPrice.total);
      setShipping(calculatedPrice.shipping);
      setItemPrice(calculatedPrice.itemPrice);
    } else {
      setPrice(0);
      setTotal(0);
      setShipping(0);
      setItemPrice(0);
    }
  }, [quantityError, orderType, area, quantity]);

  const sendConfirmationEmails = async (confirmationDetails) => {
    const orderData = JSON.parse(localStorage.getItem('orderData'));

    form.current.appendChild(
      addElemenToForm(
        'name',
        `${confirmationDetails.name.given_name} ${confirmationDetails.name.surname}`
      )
    );
    form.current.appendChild(addElemenToForm('email', confirmationDetails.email));
    form.current.appendChild(addElemenToForm('orderId', confirmationDetails.orderId));
    form.current.appendChild(addElemenToForm('orderDate', confirmationDetails.orderDate));
    form.current.appendChild(addElemenToForm('size', confirmationDetails.size));
    form.current.appendChild(
      addElemenToForm('shippingAddress', confirmationDetails.shippingAddress)
    );
    form.current.appendChild(addElemenToForm('total', orderData.total));
    form.current.appendChild(addElemenToForm('orderType', orderData.orderType.label));

    await sendEmail(form.current, null, templateIds.createOrderEmail);
    await sendEmail(form.current, null, templateIds.createOrderToCustomer);
  };

  const dimensions = () => {
    if (shape === Shape.circle) {
      return (
        <label>
          <span>Diameter:</span> {diameter} inche(s)
        </label>
      );
    } else if (shape === Shape.other) {
      return (
        <>
          <label>
            <span>Length:</span> {length} inche(s)
          </label>
          <label>
            <span>Width:</span> {width} inche(s)
          </label>
        </>
      );
    }
  };

  return (
    <>
      <Header />
      <form ref={form} className="Order">
        {isPending ? <div className="spinner" /> : null}
        <div style={{ display: newOrder ? 'inline-block' : 'none' }}>
          <div className="orderForm">
            <div style={{ margin: '.5rem' }} className="formCol">
              <label>
                <span>Shape:</span>
              </label>
              {shape === Shape.circle ? (
                <label>
                  <span>Diameter:</span>
                </label>
              ) : (
                <div className="formCol">
                  <label>
                    <span>Length:</span>
                  </label>
                  <label>
                    <span>Width:</span>
                  </label>
                </div>
              )}
              <label>
                <span>Quantity:</span>
              </label>
              <label>
                <span>Upload Image:</span>
              </label>
              <label>
                <span>Price Per Item:</span>
              </label>
              <label>
                <span>Item Total:</span>
              </label>
              <label>
                <span>Shipping:</span>
              </label>
              <label>
                <span>Total:</span>
              </label>
            </div>
            <div style={{ margin: '.5rem' }} className="formCol">
              <div className="formRow">
                <input
                  defaultChecked
                  type="radio"
                  name="shape"
                  value="Circle"
                  onChange={() => setShape(Shape.circle)}
                />
                <label>Circle</label>
                <input
                  type="radio"
                  name="shape"
                  value="Other"
                  onChange={() => setShape(Shape.other)}
                />
                <label>Other</label>
              </div>
              {shape === Shape.circle ? (
                <div className="formRow">
                  <select name="diameter" onChange={(e) => setDiameter(e.target.value)}>
                    <option value={1}>1</option>
                    <option value={1.5}>1.5</option>
                    <option value={2}>2</option>
                    <option value={2.5}>2.5</option>
                    <option value={3}>3</option>
                    <option value={3.5}>3.5</option>
                    <option value={4}>4</option>
                    <option value={4.5}>4.5</option>
                    <option value={5}>5</option>
                    <option value={5.5}>5.5</option>
                    <option value={6}>6</option>
                  </select>
                  <label>inche(s)</label>
                </div>
              ) : (
                <div className="formCol">
                  <div className="formRow">
                    <select name="length" onChange={(e) => setLength(e.target.value)}>
                      <option value={1}>1</option>
                      <option value={1.5}>1.5</option>
                      <option value={2}>2</option>
                      <option value={2.5}>2.5</option>
                      <option value={3}>3</option>
                      <option value={3.5}>3.5</option>
                      <option value={4}>4</option>
                      <option value={4.5}>4.5</option>
                      <option value={5}>5</option>
                      <option value={5.5}>5.5</option>
                      <option value={6}>6</option>
                      <option value={6.5}>6.5</option>
                      <option value={7}>7</option>
                      <option value={7.5}>7.5</option>
                      <option value={8}>8</option>
                      <option value={8.5}>8.5</option>
                      <option value={9}>9</option>
                    </select>
                    <label name="length">inche(s)</label>
                  </div>
                  <div className="formRow">
                    <select name="width" onChange={(e) => setWidth(e.target.value)}>
                      <option value={1}>1</option>
                      <option value={1.5}>1.5</option>
                      <option value={2}>2</option>
                      <option value={2.5}>2.5</option>
                      <option value={3}>3</option>
                      <option value={3.5}>3.5</option>
                      <option value={4}>4</option>
                      <option value={4.5}>4.5</option>
                      <option value={5}>5</option>
                      <option value={5.5}>5.5</option>
                      <option value={6}>6</option>
                    </select>
                    <label>inche(s)</label>
                  </div>
                </div>
              )}
              <input
                name="quantity"
                type="number"
                min="1"
                value={quantity}
                onChange={(e) => {
                  if (e.target.value <= 0 || e.target.value > 100) {
                    if (e.target.value <= 0) {
                      errorMessages['quantity'] = 'Quantity has to be greater than 0';
                      setErrorMessages(errorMessages);
                      setQuantityError(true);
                    } else {
                      errorMessages['quantity'] = 'Quantity cannot be greater than 100';
                      setErrorMessages(errorMessages);
                      setQuantityError(true);
                    }
                  } else {
                    errorMessages['quantity'] = '';
                    setErrorMessages(errorMessages);
                    setQuantityError(false);
                  }
                  setQuantity(e.target.value);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <label
                  style={{ display: imageUrl !== null ? 'none' : 'inline-block' }}
                  className="uploadImage">
                  <input
                    name="logo"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <img
                    style={{ height: '2rem', width: '2rem', justifySelf: 'center' }}
                    src={uploadImage}
                  />
                </label>
                <img
                  style={{
                    display: imageUrl === null ? 'none' : 'inline-block',
                    height: '2rem',
                    width: '2rem'
                  }}
                  src={imageUrl}
                />
                <img
                  className="removeImg"
                  onClick={() => {
                    setFile(null);
                    setImageUrl(null);
                  }}
                  style={{
                    display: imageUrl === null ? 'none' : 'inline-block',
                    height: '1rem',
                    width: '1rem'
                  }}
                  src={removeImage}
                />
              </div>
              <label>${price}</label>
              <label>${itemPrice}</label>
              <label>${shipping}</label>
              <label>${total}</label>
            </div>
          </div>
          <div className="formCol">
            <div className="buttonCol">
              <button
                style={{ alignSelf: 'stretch' }}
                className="button"
                onClick={() => navigate('/')}>
                Prev
              </button>
              <button
                style={{ alignSelf: 'stretch' }}
                disabled={quantityError || file === null || fileSizeError}
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  const orderData = {
                    orderType: orderType,
                    shape: shape,
                    diameter: diameter,
                    length: length,
                    width: width,
                    quantity: quantity,
                    price: price,
                    shipping: shipping,
                    itemPrice: itemPrice,
                    total: total,
                    image: file,
                    imageUrl: imageUrl
                  };
                  localStorage.setItem('orderData', JSON.stringify(orderData));

                  setNewOrder(false);
                }}>
                Checkout
              </button>
            </div>
            {Object.values(errorMessages).map((errorMessage, index) => (
              <p style={{ flexBasis: 0, flexShrink: 0 }} key={index} className="errorMessage">
                {errorMessage}
              </p>
            ))}
          </div>
        </div>
        <div style={{ display: newOrder ? 'none' : 'inline-block' }} className="Review">
          <div style={{ margin: '.5rem' }} className="reviewForm">
            <h1>{orderType.label} Review</h1>
            <label>
              <span>Shape:</span> {shape}
            </label>
            {dimensions()}
            <label>
              <span>Quantity:</span> {quantity}
            </label>
            <label>
              <span>Item Total:</span> ${itemPrice}
            </label>
            <label>
              <span>Shipping:</span> ${shipping} (allow up to 1 week to ship)
            </label>
            <label>
              <span>Price:</span> ${total} (${price} each)
            </label>
            <img style={{ height: 'auto', width: '10rem', alignSelf: 'center' }} src={imageUrl} />
          </div>
          <div className="formCol">
            <div className="buttonCol">
              <button
                style={{ alignSelf: 'stretch', margin: 0, marginBottom: '0.5rem' }}
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  setNewOrder(true);
                }}>
                Prev
              </button>
              <PayPalButtons
                createOrder={(data, actions) => {
                  const orderData = JSON.parse(localStorage.getItem('orderData'));
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: orderData.total,
                          breakdown: {
                            item_total: {
                              value: orderData.itemPrice,
                              currency_code: 'USD'
                            },
                            shipping: {
                              value: orderData.shipping,
                              currency_code: 'USD'
                            }
                          }
                        },
                        description: 'Patches and Stickers Order',
                        shipping: {
                          type: 'SHIPPING'
                        }
                      }
                    ]
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then(async (details) => {
                    // E-MAIL ME and CUSTOMER
                    const orderData = JSON.parse(localStorage.getItem('orderData'));
                    const shippingAddress = Object.values(
                      details.purchase_units[0].shipping.address
                    ).join(' ');
                    const size =
                      shape === Shape.circle
                        ? `${orderData.diameter}in`
                        : `${orderData.length}in x ${orderData.width}in`;

                    const confirmationDetails = {
                      name: details.payer.name,
                      email: details.payer.email_address,
                      orderId: details.id,
                      orderDate: details.create_time,
                      size: size,
                      shippingAddress: shippingAddress
                    };
                    await sendConfirmationEmails(confirmationDetails);

                    localStorage.removeItem('orderData');
                    navigate('/confirmation', {
                      state: {
                        formData: {
                          shape: orderData.shape,
                          diameter: orderData.diameter,
                          length: orderData.length,
                          width: orderData.width,
                          quantity: orderData.quantity,
                          price: orderData.price,
                          shipping: orderData.shipping,
                          itemPrice: orderData.itemPrice,
                          total: orderData.total,
                          file: file,
                          imageUrl: orderData.imageUrl,
                          orderId: details.id,
                          date: details.create_time
                        }
                      }
                    });
                  });
                }}
                onError={(err) => {
                  const values = Object.values(err);
                  let message = 'Order submission failed for the following reasons: ';
                  values.forEach((error) => {
                    message += `${error} `;
                  });

                  alert(message);
                }}
                onCancel={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    // CHECK details.status; IF COMPLETED then hide paypal buttons
                    alert(`Transactions canceled by ${details.payer.name.given_name}`);
                  });
                }}
                style={{ layout: 'vertical' }}></PayPalButtons>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OrderContainer;

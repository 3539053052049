/* eslint-disable react/display-name */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, createContext } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

export const UserContext = createContext({ user: null });
export default (props) => {
  const [user, setuser] = useState(null);
  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      const { displayName, email, photoURL } = user;
      setuser({
        displayName,
        email,
        photoURL
      });
    });
  }, []);
  return <UserContext.Provider value={user}>{props.children}</UserContext.Provider>;
};

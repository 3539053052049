export const OrderType = {
  patch: {
    label: 'Patch',
    price: 0.02
  },
  sticker: {
    label: 'Sticker',
    price: 0.01
  }
};

export const Shape = {
  circle: 'Circle',
  other: 'Other'
};

export const OrderStatus = {
  submitted: 'Submitted',
  inProgress: 'In Progress',
  complete: 'Complete',
  delivered: 'Delivered'
};

export const PriceMultiple = {
  under25: 7.5,
  over25: 5,
  over50: 4
};

export const ShippingRates = {
  under25: 5.99,
  over25: 7.99,
  over50: 9.99
};

export const templateIds = {
  inquiryEmail: 'template_z4zbt45',
  createOrderEmail: 'template_u4trw1n',
  createOrderToCustomer: 'template_y2wrnm4'
};

import { useNavigate } from 'react-router-dom';
import mainLogo from '../../assets/laptop.svg';
import './Header.css';

function Header() {
  const navigate = useNavigate();

  return (
    <header onClick={(e) => navigate('/')} className="App-header">
      <h1 style={{ color: '#ffff8b' }}>Patches</h1>
      <img src={mainLogo} className="App-logo" alt="logo" />
      <h1 style={{ color: '#ffff8b' }}>Stickers</h1>
    </header>
  );
}

export default Header;

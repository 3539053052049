import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import './Help.css';
import { sendEmail } from '../../services/OrderService';
import { templateIds } from '../../utils/Constants';
import Header from '../header/Header';

export const Help = () => {
  const [orderIdError, setOrderIdError] = useState(true);
  const [orderId, setOrderId] = useState('');
  const [nameError, setNameError] = useState(true);
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState(true);
  const [email, setEmail] = useState('');
  const [detailsError, setDetailsError] = useState(true);
  const [details, setDetails] = useState('');
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const form = useRef();
  const [errorMessages, setErrorMessages] = useState({});
  const [disableForm, setDisableForm] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.displayName);
      setNameError(false);
      setEmail(user.email);
      setEmailError(false);
    }
  }, [user]);

  useEffect(() => {
    setDisableForm(nameError || emailError || orderIdError || detailsError);
  }, [nameError, emailError, orderIdError, detailsError]);

  return (
    <>
      <Header />
      <form
        ref={form}
        className="Help"
        onSubmit={(e) => {
          e.preventDefault();
          setDisableForm(true);
          sendEmail(form.current, e, templateIds.inquiryEmail).then((success) => {
            if (success) {
              alert("E-Mail successfully sent; we'll be in touch!");
              navigate('/');
            } else {
              alert('Unexpected error with sending E-Mail; please try again at another time!');
              navigate('/');
            }
          });
        }}>
        <div style={{ alignItems: 'flex-start' }} className="orderForm">
          <div style={{ margin: '.5rem' }} className="formCol">
            <label>
              <span>Name:</span>
            </label>
            <label>
              <span>E-Mail:</span>
            </label>
            <label>
              <span>Order Number:</span>
            </label>
            <label>
              <span>Details:</span>
            </label>
          </div>
          <div style={{ margin: '.5rem' }} className="formCol">
            <input
              name="name"
              key="nameField"
              type="text"
              value={name}
              onChange={(e) => {
                if (e.target.value === '' || e.target.value === undefined) {
                  errorMessages['name'] = 'Name is required';
                  setErrorMessages(errorMessages);
                  setNameError(true);
                } else {
                  errorMessages['name'] = '';
                  setErrorMessages(errorMessages);
                  setNameError(false);
                }
                setName(e.target.value);
              }}
            />
            <input
              name="email"
              key="emailField"
              type="text"
              value={email}
              onChange={(e) => {
                if (e.target.value === '' || e.target.value === undefined) {
                  errorMessages['email'] = 'E-Mail is required';
                  setErrorMessages(errorMessages);
                  setEmailError(true);
                } else {
                  errorMessages['email'] = '';
                  setErrorMessages(errorMessages);
                  setEmailError(false);
                }
                setEmail(e.target.value);
              }}
            />
            <input
              name="orderId"
              key="orderField"
              type="text"
              value={orderId}
              onChange={(e) => {
                if (e.target.value === '' || e.target.value === undefined) {
                  errorMessages['orderId'] = 'Order ID is required';
                  setErrorMessages(errorMessages);
                  setOrderIdError(true);
                } else {
                  errorMessages['orderId'] = '';
                  setErrorMessages(errorMessages);
                  setOrderIdError(false);
                }
                setOrderId(e.target.value);
              }}
            />
            <textarea
              name="message"
              key="messageField"
              rows="6"
              maxLength={100}
              value={details}
              onChange={(e) => {
                if (e.target.value === '' || e.target.value === undefined) {
                  errorMessages['details'] = 'Details are required';
                  setErrorMessages(errorMessages);
                  setDetailsError(true);
                } else {
                  errorMessages['details'] = '';
                  setErrorMessages(errorMessages);
                  setDetailsError(false);
                }
                setDetails(e.target.value);
              }}></textarea>
          </div>
        </div>
        <div className="formCol">
          <div className="buttonRow">
            <button
              type="button"
              style={{ alignSelf: 'stretch' }}
              className="button"
              onClick={() => navigate(-1)}>
              Prev
            </button>
            <button
              style={{ alignSelf: 'stretch' }}
              disabled={disableForm}
              className="button"
              type="submit">
              Submit
            </button>
          </div>
          {Object.values(errorMessages).map((errorMessage, index) => (
            <p style={{ fontSize: '1rem' }} key={index} className="errorMessage">
              {errorMessage}
            </p>
          ))}
        </div>
      </form>
    </>
  );
};

import { Link } from 'react-router-dom';
import { OrderType } from '../../utils/Constants';
import './Home.css';
import stickerBackground from '../../assets/stickers.png';
import patchesBackground from '../../assets/patches.png';
import { useNavigate } from 'react-router-dom';
import Header from '../header/Header';

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="Home">
        <div className="cards">
          {/* <Link to="/order" state={{ orderType: OrderType.patch }} className="homeCardContainer">
          <img className="homeImage" alt="patches pic" src={patchesBackground} />
          <h1 className="cardHeader">Patches</h1>
        </Link> */}
          <Link to="/order" state={{ orderType: OrderType.sticker }} className="homeCardContainer">
            <img className="homeImage " alt="stickers pic" src={stickerBackground} />
            <h1 className="cardHeader">Stickers</h1>
          </Link>
        </div>
        <div className="questionCard">
          <h1 onClick={() => navigate('/help')}>Questions about your order?</h1>
        </div>
      </div>
    </>
  );
}

export default Home;
